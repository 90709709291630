<template>
  <div class="page-role">
    <h2 class="p-title">{{ headerTitle }}</h2>
    <yos-tab-nav :list="tabNav" />
    <bread-crumbs :list="breadList" />
    <div class="p-card">
      <div class="card-title">职责信息</div>
      <div class="left-fixed-item fix">
        <span class="left-fixed">名称</span>
        <div class="right-wrapper">
          <yos-input
            v-model.trim="name"
            placeholder="(必填)请输入职责名称"
            :disabled="$route.params.id >= 0"
          />
          <p class="tip">名称必须唯一且不能重复。创建后，名称不能更改。<br />名称最多10个字</p>
        </div>
      </div>
      <div class="left-fixed-item fix">
        <span class="left-fixed">状态</span>
        <div class="right-wrapper">
          <yos-radio name="status" :disabled="readOnly" v-model="status" :value="1" label="正常" />
          <yos-radio name="status" :disabled="readOnly" v-model="status" :value="0" label="禁用" />
        </div>
      </div>
      <div class="left-fixed-item fix">
        <span class="left-fixed">权限</span>
        <div class="right-wrapper right-box overflow-auto">
          <yos-tree
            :treeData="treeData"
            :flatData="flatData"
            :change="onChange"
            :checkedIds="checkedIds"
            :disabled="readOnly"
          />
        </div>
      </div>
      <div class="card-title no-top">其他信息</div>
      <div class="left-fixed-item fix">
        <span class="left-fixed">已授权</span>
        <div class="right-wrapper"><span>{{ accountTotal }}</span>个账号</div>
      </div>
      <bottom-options :readOnly="readOnly" @save="onSave" />
    </div>
  </div>
</template>

<script>
import BreadCrumbs from '../../components/common/bread-crumbs'
import BottomOptions from '../../components/common/bottom-options'
import YosTree from '../../components/other/yos-tree'
import YosRadio from '../../components/form/yos-radio'
import YosPager from '../../components/other/yos-pager'
import YosTabNav from '../../components/other/tab-nav'
import { ref, computed, reactive, toRefs } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useServer, useTabNav } from '../../hook'
import { addClass, setLocalUser } from '../../util'
import {
  getRight,
  getRole,
  updateRole,
  addRole,
  getUser
} from '../../data'

export default {
  components: {
    BreadCrumbs,
    BottomOptions,
    YosTree,
    YosRadio,
    YosPager,
    YosTabNav
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const { id } = route.params
    const headerTitle = ref('创建职责权限')
    const nav = [{
      name: '账号列表',
      to: '/setting/accounts'
    }, {
      name: '职责列表',
      to: '/setting/roles',
      active: true
    }]
    const breadList = ref([{
      name: '职责列表',
      to: '/setting/roles'
    }])
    const state = reactive({
      name: '',
      status: 1,
      accountTotal: 0
    })
    const flatData = ref([])
    const treeData = ref([])
    const checkedIds = reactive({})
    const readOnly = computed(() => state.name === '超级管理员')

    const getTreeData = (data) => {
      let newData = []
      let childrenData = []

      data.forEach(item => {
        if (!item.parentId) {
          newData.push(item)
        } else {
          childrenData.push(item)
        }
      })

      function formatData (data, children) {
        data.forEach(item => {
          let next = []
          let other = []

          children.forEach(entry => {
            if (entry.parentId === item.id) {
              next.push(entry)
            } else {
              other.push(entry)
            }
          })

          if (next.length > 0) {
            item.children = next
            formatData(item.children, other)
          }
        })
      }

      formatData(newData, childrenData)

      return newData
    }

    const setRightParams = useServer(getRight, ({ rights }) => {
      flatData.value = [
        ...rights,
        {
          id: -1,
          name: '全部权限',
          show: true
        }
      ]
      treeData.value = getTreeData(flatData.value)
    })

    const setRoleParams = useServer(getRole, ({ name, status, accountTotal, rightIds }) => {
      state.name = name
      state.status = status
      state.accountTotal = accountTotal
      rightIds.split(',').forEach(item => {
        checkedIds[item] = true
      })
    })

    const setUserParams = useServer(getUser, (data) => {
      setLocalUser(data)
    })

    const success = () => {
      setUserParams()
      router.back()
    }

    const setUpdateRoleParams = useServer(updateRole, success)

    const setAddRoleParams = useServer(addRole, success)

    const onSave = async () => {
      const rightIds = Object.keys(checkedIds).filter(key => checkedIds[key]).join(',')

      if (id) {
        setUpdateRoleParams({id, status: state.status, rightIds})
      } else {
        setAddRoleParams({name: state.name, status: state.status, rightIds})
      }
    }

    const onChange = (ids) => {
      checkedIds = ids
    }

    if (id >= 0) {
      setRoleParams({id})
      headerTitle.value = '编辑职责权限'
    }

    breadList.value.push({name: headerTitle.value})
    setRightParams()

    return {
      tabNav: useTabNav(nav),
      ...toRefs(state),
      checkedIds,
      flatData,
      treeData,
      readOnly,
      addClass,
      headerTitle,
      breadList,
      getTreeData,
      onChange,
      onSave
    }
  }
}
</script>

<style lang="postcss">
.page-role {
  .yos-radio {
    margin-right: 10px;
  }

  .tip {
    color: #999;
    line-height: 1.5;
    padding-top: 10px;
  }
}
</style>
